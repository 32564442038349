import React from "react";
import { Link } from "react-router-dom";

interface Props {
  isCollapsed: boolean;
  className?: string;
}

const Head: React.FC<Props> = ({ isCollapsed, className }) => (
  <Link
    to={{
      pathname: "/",
    }}
  >
    <div
      className={`flex items-center justify-start p-6 ${className}`}
      title={`BayChem v${0.1}`}
    >
      <span
        className={`-mb-px text-white uppercase transition-opacity duration-300 ease-out text-s ${
          isCollapsed ? "opacity-0" : "opacity-100"
        }`}
      >
        <span className="font-extrabold">Alpha</span>
        Fold
      </span>
    </div>
  </Link>
);

export default Head;
