import React from "react";
import { LdTypo } from "@emdgroup-liquid/liquid/dist/react";

import { ComponentProps } from "types/app";
import { File } from "@alphafold/types";
import FileInfo from "components/FileInfo";

interface Props extends ComponentProps {
  files: File[];
  labelExtractor?: (file: File) => string | undefined;
  onFileDownload?: (file: File) => void;
}

const FileList: React.FC<Props> = ({
  files,
  labelExtractor,
  onFileDownload,
}) => {
  return (
    <table className="flex w-full flex-col divide-y divide-sensitive-grey-darker">
      <tbody>
        {files.map((file) => (
          <tr key={file.downloadUrl} className="py-ld-4">
            {labelExtractor && (
              <td>
                <LdTypo variant="label-s">{labelExtractor(file)}</LdTypo>
              </td>
            )}
            <td>
              <FileInfo file={file} onFileDownload={onFileDownload} />
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default FileList;
