import { File } from "@alphafold/types";

export function formatFileSize(bytes: number | string, decimalPoint: number) {
  const bytesInt = typeof bytes === "string" ? parseInt(bytes) : bytes;
  if (bytesInt == 0) return "0 Bytes";
  var k = 1000,
    dm = decimalPoint || 2,
    sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"],
    i = Math.floor(Math.log(bytesInt) / Math.log(k));
  return parseFloat((bytesInt / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
}

export function downloadFile(url: string, fileName?: string) {
  var link = document.createElement("a");
  if (link.download !== undefined) {
    // feature detection
    // Browsers that support HTML5 download attribute
    link.setAttribute("href", url);
    link.setAttribute("target", "_blank");
    link.setAttribute("download", fileName || "download");
    link.style.visibility = "hidden";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
}
export function getFileExtension(fileName: string) {
  return fileName.split(".").pop();
}

export function getFileLabel(file: File) {
  let label = undefined;
  if (!file.fileName) return label;
  const extension = getFileExtension(file.fileName);
  switch (extension) {
    case "pdb":
      label = "Best prediction";
      break;
    case "zip":
      label = "Full results";
  }
  return label;
}

export async function blobToBase64(
  blob: Blob,
  keepDeclaration = false
): Promise<string> {
  const regex = /data:.+\/.+;base64,/gm;

  const b: string = await new Promise((resolve) => {
    const reader = new FileReader();
    reader.onloadend = () => resolve(reader.result as string);
    reader.readAsDataURL(blob);
  });
  if (keepDeclaration) {
    return b;
  }
  return b.replace(regex, "");
}
