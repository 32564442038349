import React from "react";
import moment from "moment";
import { LdTooltip, LdTypo } from "@emdgroup-liquid/liquid/dist/react";
import { Job } from "@alphafold/types";
import { JobStatusBadge } from "components/Badge";
import { ComponentProps } from "types/app";
import useStatusTime from "../hooks/useStatusTime";

interface Props extends ComponentProps {
  job: Job;
}

const JobDetails: React.FC<Props> = ({ job }) => {
  const { changedTimeString } = useStatusTime(job);
  return (
    <div className="flex flex-col justify-between w-full gap-4">
      <div className="flex flex-row justify-between">
        <LdTypo variant="h3">{job.jobName}</LdTypo>
        <LdTooltip arrow size="sm">
          <JobStatusBadge status={job.status} slot="trigger" />
          <LdTypo variant="body-xs">{job.status}</LdTypo>
        </LdTooltip>
      </div>
      <LdTypo variant="body-xs" className="mt-[-1rem]">
        {moment(job.createdAt).format("L LT")}
      </LdTypo>
      <LdTypo variant="body-xs" className="mt-[-1rem]">
        {changedTimeString}
      </LdTypo>
    </div>
  );
};

export default JobDetails;
