import React, { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";

import LinearProgress from "@mui/material/LinearProgress";
import { LdButton, LdTypo } from "@emdgroup-liquid/liquid/dist/react";

import { useDispatch, useSelector } from "store";
import {
  deleteJob,
  listInputFiles,
  listJobs,
  listResultFiles,
  selectInputFiles,
  selectJob,
  selectResultFiles,
} from "services/jobs";

import AppLayout from "layout/AppLayout";
import DeleteJobModal from "components/Modals/DeleteJobModal";
import Card from "components/Card";

import FileList from "./components/FileList";
import JobDetails from "./components/JobDetails";
import ProteinList from "./components/ProteinList";
import Breadcrumbs from "./components/Breadcrumbs";

import assert from "assert";
import { StateStatus } from "types/app";
import AuditTrailList from "./components/AuditTrailList";
import { getFileLabel } from "util/files";
import { useMatomo } from "@jonkoops/matomo-tracker-react";

const DOCUMENT_TITLE = "Job Details";

const Job = () => {
  const { jobId = "" } = useParams();
  const dispatch = useDispatch();

  const job = useSelector(selectJob(jobId));
  const inputFiles = useSelector(selectInputFiles)(jobId);
  const resultFiles = useSelector(selectResultFiles)(jobId);
  const { status } = useSelector((state) => state.jobs);

  const [modalOpen, setModalOpen] = useState(false);

  const isLoading = useMemo(() => status === StateStatus.loading, [status]);

  const { trackPageView, trackEvent } = useMatomo();

  useEffect(() => {
    trackPageView({
      documentTitle: DOCUMENT_TITLE,
    });
  }, [trackPageView]);

  useEffect(() => {
    if (jobId !== "") {
      dispatch(listJobs());
      dispatch(listInputFiles(jobId));
      dispatch(listResultFiles(jobId));
    }
  }, [jobId]);

  const handleDeleteClicked = () => {
    setModalOpen(true);
  };

  const handleDeleteJob = () => {
    assert(job.jobId);
    trackEvent({
      category: "job-details",
      action: "delete-job",
      name: job.jobId,
      documentTitle: DOCUMENT_TITLE,
      customDimensions: [
        {
          id: 1,
          value: "job-details",
        },
      ],
    });
    dispatch(deleteJob(job.jobId));
    setModalOpen(false);
  };

  return (
    <AppLayout>
      {isLoading && <LinearProgress color="secondary" />}
      <div className="relative flex max-w-[1024px] mx-auto">
        <Breadcrumbs jobId={job.jobId} jobName={job?.jobName} />
      </div>
      <div
        id="job-details"
        className="relative flex-col flex max-w-[1024px] mx-auto gap-ld-16 justify-center"
      >
        <div className="relative flex flex-col items-start w-full gap-ld-16">
          <Card className="w-full gap-ld-8">
            <JobDetails job={job} />
            <div className="flex w-full flex-row justify-end gap-ld-8">
              <LdButton
                size="sm"
                mode="danger"
                onClick={(e) => {
                  e.stopPropagation();
                  handleDeleteClicked();
                }}
              >
                Delete Job
              </LdButton>
            </div>
          </Card>
          <Card className="w-full">
            <div className="flex flex-col justify-start items-start w-full gap-ld-8">
              <LdTypo variant="h5">Input</LdTypo>
              {job.proteins !== undefined && (
                <ProteinList title="Proteins" proteins={job.proteins} />
              )}
              <hr className="bg-black h-0 border-sensitive-grey-darkest w-full" />
              <LdTypo variant="label-s">Files</LdTypo>
              <FileList
                files={inputFiles}
                onFileDownload={(file) => {
                  trackEvent({
                    category: "job-details",
                    action: "download-input",
                    name: file.fileName,
                    documentTitle: DOCUMENT_TITLE,
                    customDimensions: [
                      {
                        id: 1,
                        value: "job-details",
                      },
                    ],
                  });
                }}
              />
            </div>
          </Card>
          {resultFiles.length > 0 && (
            <Card className="w-full gap-ld-8">
              <div className="flex flex-col justify-start items-start w-full gap-ld-8">
                <LdTypo variant="h5">Results</LdTypo>
                <FileList
                  files={resultFiles}
                  labelExtractor={getFileLabel}
                  onFileDownload={(file) => {
                    trackEvent({
                      category: "job-details",
                      action:
                        file.fileName &&
                        file.fileName?.indexOf("_ranked_0.pdb") > -1
                          ? "download-best-guess"
                          : "download-full-results",
                      name: file.fileName,
                      documentTitle: DOCUMENT_TITLE,
                      customDimensions: [
                        {
                          id: 1,
                          value: "job-details",
                        },
                      ],
                    });
                  }}
                />
              </div>
            </Card>
          )}
          {job.auditTrail && (
            <Card className="w-full gap-ld-8">
              <div className="flex flex-col justify-start items-start w-full gap-ld-8">
                <LdTypo variant="h5">Status</LdTypo>
                <AuditTrailList trail={job.auditTrail} />
              </div>
            </Card>
          )}
        </div>
      </div>
      <DeleteJobModal
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        onDelete={handleDeleteJob}
      />
    </AppLayout>
  );
};

export default Job;
