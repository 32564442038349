import React, { FC, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import LinearProgress from "@mui/material/LinearProgress";
import assert from "assert";

import { useDispatch, useSelector } from "store";
import { useUser } from "services/auth";
import {
  deleteJob,
  listInputFiles,
  listJobs,
  listResultFiles,
  selectInputFiles,
  selectJobList,
  selectResultFiles,
} from "services/jobs";
import AppLayout from "layout/AppLayout";
import JobList from "./components/List/JobList";
import JobSearchBar from "./components/JobSearchBar";
import DeleteJobModal from "components/Modals/DeleteJobModal";
import Empty from "./components/Empty";

import { isFile } from "@alphafold/types";

import { StateStatus } from "types/app";
import { useMatomo } from "@jonkoops/matomo-tracker-react";

const DOCUMENT_TITLE = "Job List";

const Dashboard: FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { info } = useUser();
  const jobs = useSelector(selectJobList);
  const inputFileSelecor = useSelector(selectInputFiles);
  const resultFileSelecor = useSelector(selectResultFiles);
  const { status, searchQuery } = useSelector((state) => state.jobs);
  const { status: userStatus } = useSelector((state) => state.user);

  const [selectedJobId, setSelectedJobId] = useState<string>();

  const { trackPageView, trackEvent } = useMatomo();
  useEffect(() => {
    trackPageView({
      documentTitle: DOCUMENT_TITLE,
    });
  }, []);

  useEffect(() => {
    dispatch(listJobs());
  }, [info]);

  const isLoading = useMemo(
    () => status === StateStatus.loading || userStatus === StateStatus.loading,
    [status, userStatus]
  );

  const handleGetInputFiles = (jobId: string) => {
    dispatch(listInputFiles(jobId));
  };

  const handleDownloadResults = async (jobId: string) => {
    const { payload } = await dispatch(listResultFiles(jobId));
    assert(Array.isArray(payload));
    assert(payload.map(isFile));
    // payload.forEach(({ fileName, downloadUrl }) => {
    //   if (fileName && downloadUrl) downloadFile(downloadUrl, fileName);
    // });
  };

  const handleDeleteClicked = (jobId: string) => {
    setSelectedJobId(jobId);
  };

  const handleDeleteJob = () => {
    assert(selectedJobId);
    dispatch(deleteJob(selectedJobId));
    setSelectedJobId(undefined);
  };

  const handleNavigateToJob = (jobId: string) => {
    trackEvent({
      category: "job-details",
      action: "show-details",
      name: jobId,
      documentTitle: DOCUMENT_TITLE,
      customDimensions: [
        {
          id: 1,
          value: "job-list",
        },
      ],
    });
    navigate(`/jobs/${jobId}`);
  };

  return (
    <AppLayout>
      {isLoading && <LinearProgress color="secondary" />}
      <div className="relative flex max-w-[1024px] mx-auto">
        <JobSearchBar />
      </div>

      <div className="relative flex flex-col max-w-[1024px] mx-auto">
        {!jobs.length && (
          <Empty loading={isLoading} searching={Boolean(searchQuery)} />
        )}
        <JobList
          jobs={jobs}
          inputFileSelecor={inputFileSelecor}
          resultFileSelecor={resultFileSelecor}
          onClick={handleNavigateToJob}
          onDeleteJob={handleDeleteClicked}
          onGetInput={handleGetInputFiles}
          onGetResults={handleDownloadResults}
        />
      </div>
      <DeleteJobModal
        open={selectedJobId !== undefined}
        onClose={() => setSelectedJobId(undefined)}
        onDelete={handleDeleteJob}
      />
    </AppLayout>
  );
};

export default Dashboard;
