/* eslint-disable import/named */
import {
  createAsyncThunk,
  createSlice,
  AnyAction,
  PayloadAction,
} from "@reduxjs/toolkit";
import axios from "axios";
import { File as AFFile, isFile, isJob, Job } from "@alphafold/types";
import { del, get, post } from "util/api";
import { FileStatus, StateStatus } from "types/app";
import assert from "assert";
import { PendingAction, RejectedAction } from "store/store";

interface InputFileState extends AFFile {
  status: FileStatus;
}

export interface DraftState {
  jobName?: string;
  jobId?: string;
  inputFiles: InputFileState[];
  status: StateStatus;
  error: string | null;
}

export const initialState: DraftState = {
  inputFiles: [],
  status: StateStatus.idle,
  error: null,
};

export const createJob = createAsyncThunk(
  "draft/createJob",
  async (body: { jobName: string }) => {
    const data = await post("job", body);
    return data;
  }
);

export const uploadInputFile = createAsyncThunk(
  "draft/uploadInputFile",
  async (parameters: { jobId: string; file: File }, { dispatch }) => {
    const { jobId, file } = parameters;
    const inputFile: AFFile = {
      fileName: file.name,
      fileSize: file.size.toString(),
    };
    dispatch(setInputFiles([{ ...inputFile, status: FileStatus.uploading }]));
    const data = await get(`job/${jobId}/upload?fileName=${file.name}`);
    dispatch(setInputFiles([{ ...inputFile, status: FileStatus.validating }]));
    // const formData = new FormData();
    // formData.append("file", file as Blob, file.name);
    await axios.put(data.url, file, {
      headers: { "x-amz-server-side-encryption": "AES256" },
    });
    // await fetch(data.url, {
    //   method: "PUT",
    //   headers: {
    //     "Content-Type": "multipart/form-data",
    //   },
    //   body: formData,
    // });
    try {
      await post(`job/${jobId}/input`, [inputFile]);
      return {
        ...inputFile,
        status: FileStatus.valid,
      } as InputFileState;
    } catch {
      return {
        ...inputFile,
        status: FileStatus.invalid,
      } as InputFileState;
    }
  }
);

export const setDraftFromJob = createAsyncThunk(
  "draft/setDraftFromJob",
  async (job: Job) => {
    const { jobId, jobName } = job;
    let inputFiles: AFFile[] = [];
    if (jobId) {
      const data = await get(`job/${jobId}/input`);
      assert(data.map(isFile));
      inputFiles = data;
    }

    return { jobId, jobName, inputFiles };
  }
);

export const submitDraft = createAsyncThunk(
  "draft/submitDraft",
  async (jobId: string) => {
    return post(`job/${jobId}/submit`);
  }
);

function isPendingAction(action: AnyAction): action is PendingAction {
  return action.type.endsWith("/pending") && action.type.startsWith("draft/");
}
function isRejectedAction(action: AnyAction): action is RejectedAction {
  return action.type.endsWith("/rejected") && action.type.startsWith("draft/");
}

export const jobsSlice = createSlice({
  name: "jobs",
  initialState,
  reducers: {
    setName: (state, action: PayloadAction<string | undefined>) => {
      state.jobName = action.payload;
    },
    setJobId: (state, action: PayloadAction<string | undefined>) => {
      state.jobId = action.payload;
    },
    setInputFiles: (state, action: PayloadAction<InputFileState[]>) => {
      state.inputFiles = action.payload;
    },
    cancelDraft: (state) => {
      if (state.jobId) {
        del(`job/${state.jobId}`);
      }
      state.status = StateStatus.idle;
      state.error = null;
      state.jobId = undefined;
      state.jobName = undefined;
      state.inputFiles = [];
    },
  },
  extraReducers: (builder) => {
    builder.addCase(createJob.fulfilled, (state, { payload }) => {
      state.status = StateStatus.succeeded;
      assert(isJob(payload));
      const job = payload as Job;
      state.jobId = job.jobId;
      state.jobName = job.jobName;
    });
    builder.addCase(setDraftFromJob.fulfilled, (state, { payload }) => {
      state.status = StateStatus.succeeded;
      Object.assign(state, payload);
    });
    builder.addCase(uploadInputFile.fulfilled, (state, { payload }) => {
      state.status = StateStatus.succeeded;
      state.inputFiles = [payload];
    });
    builder.addCase(submitDraft.fulfilled, (state) => {
      dispatchEvent(
        new CustomEvent("ldNotificationAdd", {
          detail: {
            content: `Job "${state.jobName}" has been queued`,
            type: "info",
          },
        })
      );
      state.status = StateStatus.idle;
      state.error = null;
      state.jobId = undefined;
      state.jobName = undefined;
      state.inputFiles = [];
    });
    builder
      .addMatcher(isPendingAction, (state) => {
        state.status = StateStatus.loading;
        state.error = null;
      })
      .addMatcher(isRejectedAction, (state, action) => {
        state.status = StateStatus.failed;
        state.error = (action.error as Error).message;
      });
  },
});

export const { setName, setJobId, setInputFiles, cancelDraft } =
  jobsSlice.actions;

export default jobsSlice.reducer;
