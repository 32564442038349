/* eslint-disable import/named */
import { createAsyncThunk, createSlice, AnyAction } from "@reduxjs/toolkit";
import { assertUser, User } from "@alphafold/types";
import { StateStatus } from "types/app";
import { PendingAction, RejectedAction } from "store/store";
import { get, post } from "util/api";

export interface UserState {
  user?: User;
  status: StateStatus;
  error: string | null;
}

const initialState: UserState = {
  status: StateStatus.idle,
  error: null,
};

function isPendingAction(action: AnyAction): action is PendingAction {
  return action.type.endsWith("/pending") && action.type.startsWith("user/");
}
function isRejectedAction(action: AnyAction): action is RejectedAction {
  return action.type.endsWith("/rejected") && action.type.startsWith("user/");
}

export const createUser = createAsyncThunk("user/createUser", async () => {
  const user = await post("user", {});
  assertUser(user);
  return user;
});

export const getUser = createAsyncThunk("user/getUser", async () => {
  const response = await get("user");
  assertUser(response);
  return response;
});

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getUser.fulfilled, (state, { payload }) => {
      state.status = StateStatus.succeeded;
      assertUser(payload);
      state.user = payload;
    });
    builder.addCase(createUser.fulfilled, (state, { payload }) => {
      state.status = StateStatus.succeeded;
      assertUser(payload);
      state.user = payload;
    });
    builder
      .addMatcher(isPendingAction, (state) => {
        state.status = StateStatus.loading;
        state.error = null;
      })
      .addMatcher(isRejectedAction, (state, action) => {
        state.status = StateStatus.failed;
        state.error = (action.error as Error).message;
      });
  },
});

export default userSlice.reducer;
