export interface ComponentProps {
  children?: JSX.Element | JSX.Element[] | null | any;
  className?: string;
  [key: string]: any;
}

export enum StateStatus {
  idle = "idle",
  loading = "loading",
  succeeded = "succeeded",
  failed = "failed",
}

export enum FileStatus {
  uploading = "uploading",
  validating = "validating",
  valid = "valid",
  invalid = "invalid",
}

export interface Feedback {
  subject?: string;
  message?: string;
}

export interface BugFormData {
  title?: string;
  stepsToReproduce?: string;
  expectedResult?: string;
  systemInformation?: string;
  attachment?: File;
}
