/* eslint-disable import/named */
import {
  createAsyncThunk,
  createSlice,
  AnyAction,
  PayloadAction,
} from "@reduxjs/toolkit";
import { File, isFile, isJob, Job } from "@alphafold/types";
import { del, get, post } from "util/api";
import { StateStatus } from "types/app";
import assert from "assert";
import _ from "lodash";
import { PendingAction, RejectedAction } from "store/store";
import { filterByTerm } from "util/search";
import { submitDraft } from "./draft";

export interface JobsState {
  jobs: { [jobId: string]: Job };
  inputFiles: { [jobId: string]: File[] };
  resultFiles: { [jobId: string]: File[] };
  searchQuery?: string;
  sortBy: string;
  order: "asc" | "desc";
  status: StateStatus;
  error: string | null;
}

export const initialState: JobsState = {
  jobs: {},
  inputFiles: {},
  resultFiles: {},
  sortBy: "createdAt",
  order: "desc",
  status: StateStatus.idle,
  error: null,
};

export const listJobs = createAsyncThunk("jobs/listJobs", async () => {
  return get("job");
});

export const listInputFiles = createAsyncThunk(
  "jobs/listInputFiles",
  async (jobId: string) => {
    return get(`job/${jobId}/input`);
  }
);

export const listResultFiles = createAsyncThunk(
  "jobs/listResultFiles",
  async (jobId: string) => {
    return get(`job/${jobId}/result`);
  }
);

export const createJob = createAsyncThunk(
  "jobs/createJob",
  async (body: { jobName: string }) => {
    const data = await post("job", body);
    return data;
  }
);

export const deleteJob = createAsyncThunk("jobs/deleteJob", (jobId: string) => {
  return del(`job/${jobId}`);
});

function isPendingAction(action: AnyAction): action is PendingAction {
  return action.type.endsWith("/pending") && action.type.startsWith("jobs/");
}
function isRejectedAction(action: AnyAction): action is RejectedAction {
  return action.type.endsWith("/rejected") && action.type.startsWith("jobs/");
}

export const jobsSlice = createSlice({
  name: "jobs",
  initialState,
  reducers: {
    changeOrder: (state, action: PayloadAction<"asc" | "desc">) => {
      state.order = action.payload;
    },
    setQuery: (state, action: PayloadAction<string | undefined>) => {
      state.searchQuery = action.payload;
    },
    setSortyBy: (state, action: PayloadAction<string>) => {
      state.sortBy = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(listJobs.fulfilled, (state, { payload }) => {
      assert(Array.isArray(payload));
      assert(payload.map(isJob));
      state.status = StateStatus.succeeded;
      Object.assign(
        state.jobs,
        ...payload.map((job) => ({
          [job.jobId!]: job,
        }))
      );
    });
    builder.addCase(listInputFiles.fulfilled, (state, { payload, meta }) => {
      assert(Array.isArray(payload));
      assert(payload.map(isFile));
      state.status = StateStatus.succeeded;
      state.inputFiles[meta.arg] = payload;
    });
    builder.addCase(listResultFiles.fulfilled, (state, { payload, meta }) => {
      assert(Array.isArray(payload));
      assert(payload.map(isFile));
      state.status = StateStatus.succeeded;
      state.resultFiles[meta.arg] = payload;
    });
    builder.addCase(createJob.fulfilled, (state, { payload }) => {
      state.status = StateStatus.succeeded;
      assert(isJob(payload));
      const job = payload as Job;
      state.jobs[job.jobId!] = job;
    });
    builder.addCase(submitDraft.fulfilled, (state, { payload }) => {
      state.status = StateStatus.succeeded;
      assert(isJob(payload));
      assert(payload.jobId);
      state.jobs[payload.jobId] = payload;
    });
    builder.addCase(deleteJob.pending, (state, { meta }) => {
      state.status = StateStatus.loading;
      delete state.jobs[meta.arg];
    });
    builder.addCase(deleteJob.fulfilled, (state, { meta }) => {
      state.status = StateStatus.succeeded;
      dispatchEvent(
        new CustomEvent("ldNotificationAdd", {
          detail: {
            content: `Job with id "${meta.arg}" has been deleted`,
            type: "info",
          },
        })
      );
    });
    builder
      .addMatcher(isPendingAction, (state) => {
        state.status = StateStatus.loading;
        state.error = null;
      })
      .addMatcher(isRejectedAction, (state, action) => {
        state.status = StateStatus.failed;
        state.error = (action.error as Error).message;
      });
  },
});

export const selectJobList = ({
  jobs: { jobs, searchQuery, sortBy, order },
}: {
  jobs: JobsState;
}) =>
  _.orderBy(
    Object.values(jobs).filter(
      ({ jobName }) =>
        !searchQuery || (jobName && filterByTerm(jobName, searchQuery))
    ),
    [sortBy],
    [order]
  );

export const selectJob =
  (jobId: string) =>
  ({ jobs: { jobs } }: { jobs: JobsState }) =>
    jobs[jobId] || {};

export const selectInputFiles =
  ({ jobs: { inputFiles } }: { jobs: JobsState }) =>
  (jobId: string) =>
    inputFiles[jobId] || [];

export const selectResultFiles =
  ({ jobs: { resultFiles } }: { jobs: JobsState }) =>
  (jobId: string) =>
    resultFiles[jobId] || [];

export const { setSortyBy, changeOrder, setQuery } = jobsSlice.actions;

export default jobsSlice.reducer;
