import React from "react";

import { ReactComponent as LogoutIcon } from "assets/static/images/icons/icon-logout.svg";

import "./style.scss";
import { LdIcon } from "@emdgroup-liquid/liquid/dist/react";
import { useUser } from "services/auth";

interface Props {
  className?: string;
  isCollapsed?: boolean;
}

const User: React.FC<Props> = ({ isCollapsed, className }) => {
  const { info, logout } = useUser();
  const userLabel = info?.email;

  return (
    <div className={className}>
      <div
        className={`h-px bg-sensitive-grey${
          info ? " opacity-25" : " opacity-0"
        }`}
      />
      <div
        className={`flex ${
          !isCollapsed ? "justify-start" : "justify-center"
        } px-6 py-4`}
      >
        {!isCollapsed && (
          <div
            data-test="sidebar-user-label"
            className="flex-grow -mt-px font-bold text-white truncate whitespace-no-wrap"
          >
            {!info ? "" : userLabel}
          </div>
        )}
        {info && (
          <button
            data-test="sidebar-user-button-logout"
            onClick={logout}
            title="Logout"
            className="ml-0 md:ml-2 focus:outline-none sidebar__user-button-logout"
          >
            <LdIcon>
              <LogoutIcon />
            </LdIcon>
          </button>
        )}
      </div>
      <div
        className={`h-px bg-sensitive-grey${
          info ? " opacity-25" : " opacity-0"
        }`}
      />
    </div>
  );
};

export default User;
