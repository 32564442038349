import React, { FC, useEffect, useMemo, useState } from "react";
import {
  LdButton,
  LdInput,
  LdLabel,
  LdStep,
  LdStepper,
  LdTypo,
} from "@emdgroup-liquid/liquid/dist/react";
import assert from "assert";
import { useDispatch, useSelector } from "store";
import {
  cancelDraft,
  createJob,
  submitDraft,
  uploadInputFile,
} from "services/draft";
import AppLayout from "layout/AppLayout";
import UploadDropzone from "components/UploadDropZone";

import Card from "components/Card";
import FileInfo from "components/FileInfo";
import { FileStatus, StateStatus } from "types/app";
import { useNavigate } from "react-router-dom";
import { useMatomo } from "@jonkoops/matomo-tracker-react";

const steps = [
  {
    order: 0,
    label: "Name Your Job",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
  },
  {
    order: 1,
    label: "Select Fasta File",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
  },
  {
    order: 2,
    label: "Review",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
  },
];

const Upload: FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const draft = useSelector((state) => state.draft);
  // const [currentStep, setCurrentStep] = useState(0);
  const [jobName, setJobName] = useState<string>();
  // const [inputFile, setInputFile] = useState<File>();
  // const { status } = useSelector((state) => state.jobs);

  const { trackPageView, trackEvent } = useMatomo();

  useEffect(() => {
    trackPageView({
      documentTitle: "New Job",
    });
  }, []);

  const currentStep = useMemo(() => {
    if (!draft.jobName) return 0;
    if (draft.inputFiles.length === 0) return 1;
    return 2;
  }, [draft]);

  const handleCreateJob = async () => {
    assert(jobName);
    trackEvent({
      category: "new-job",
      action: "enter-name",
      documentTitle: "New Job",
    });
    await dispatch(createJob({ jobName }));
    // setCurrentStep(1);
  };

  const handleDropFile = async (files: File[] | FileList) => {
    const file = files[0];
    if (!!file) {
      assert(draft.jobId);
      trackEvent({
        category: "new-job",
        action: "upload-file",
        documentTitle: "New Job",
      });
      dispatch(uploadInputFile({ jobId: draft.jobId, file }));
    }
  };

  const handleNext = () => {
    switch (currentStep) {
      case 0:
        handleCreateJob();
        break;
      case 2:
        handleSubmit();
        return;
      default:
        return;
    }
  };

  const handleCancel = () => {
    trackEvent({
      category: "new-job",
      action: "cancel-creation",
      documentTitle: "New Job",
    });
    dispatch(cancelDraft());
  };

  const handleSubmit = async () => {
    assert(draft.jobId);
    trackEvent({
      category: "new-job",
      action: "submit-job",
      documentTitle: "New Job",
    });
    dispatch(submitDraft(draft.jobId));
    navigate("/jobs");
  };

  const disabled = useMemo(() => {
    switch (currentStep) {
      case 0:
        return !jobName;
      case 1:
        return draft.inputFiles.length < 1;
      case 2:
        for (let i = 0; i < draft.inputFiles.length; i++) {
          const { status } = draft.inputFiles[i];
          if (status !== FileStatus.valid) return true;
        }
        return false;
      default:
        return true;
    }
  }, [currentStep, draft, jobName]);

  return (
    <AppLayout>
      <div
        id="upload"
        className="relative flex max-w-[1024px] mx-auto gap-ld-16 pt-ld-32 justify-center"
      >
        <div className="relative flex flex-col items-start w-full mt-ld-16">
          <Card className="w-full gap-ld-8">
            <div className="z-10">
              <LdStepper fit-content>
                {steps.map(({ label, order }) => (
                  <LdStep
                    key={order}
                    done={order < currentStep}
                    current={order == currentStep}
                  >
                    {label}
                  </LdStep>
                ))}
              </LdStepper>
            </div>
            <>
              <div className="flex flex-col w-full justify-start">
                <LdLabel>
                  Job Name:
                  {!draft.jobName ? (
                    <LdInput
                      onInput={(ev) => {
                        let value = (ev.target as HTMLLdInputElement).value;
                        setJobName(value);
                      }}
                    />
                  ) : (
                    <LdTypo variant="h2">{draft.jobName}</LdTypo>
                  )}
                </LdLabel>
                {draft.inputFiles.length > 0 && (
                  <LdLabel>
                    Input:
                    {draft.inputFiles.map(({ status, ...file }) => (
                      <FileInfo
                        key={file.fileName}
                        file={file}
                        status={status}
                      />
                    ))}
                  </LdLabel>
                )}
              </div>
              {currentStep === 1 && (
                <UploadDropzone
                  className="my-ld-16"
                  onDrop={(files: File[]) => handleDropFile(files)}
                />
              )}
            </>
            <div className="w-full flex flex-row justify-end gap-ld-8">
              <LdButton onClick={handleCancel} mode="danger-secondary">
                Cancel
              </LdButton>
              <LdButton
                onClick={handleNext}
                disabled={disabled}
                progress={
                  draft.status === StateStatus.loading ? "pending" : undefined
                }
              >
                {currentStep === steps.length - 1 ? "Submit" : "Next"}
              </LdButton>
            </div>
          </Card>
        </div>
      </div>
    </AppLayout>
  );
};

export default Upload;
