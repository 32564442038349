import * as React from "react";
import Navigation from "./components/Navigation";

import "../components/styles.scss";
import { LdNotification } from "@emdgroup-liquid/liquid/dist/react";

type Props = {
  children?: React.ReactNode;
};

const AppLayout: React.FC<Props> = ({ children }) => {
  return (
    <>
      <LdNotification placement="top" />
      <div className="fixed flex flex-col items-stretch w-full h-screen overflow-hidden bg-sensitive-grey text-rich-blue-darkest">
        <div className="flex flex-grow h-full overflow-auto">
          <Navigation className="h-full" />
          <main className="flex-grow h-full min-h-screen overflow-auto">
            {children}
          </main>
        </div>
      </div>
    </>
  );
};

export default AppLayout;
